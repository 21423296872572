import {Box, Button, Grid, Group, Image, Radio, Stack, Text, useMantineTheme} from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useState } from "react";

import Cookies from "universal-cookie";
import { IconMan, IconWoman } from "@tabler/icons-react";
import useStore from "../../store/useStore";
import { useMediaQuery } from "@mantine/hooks";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const AlertSelectionGender = ({data, ...props}: Props) => {
    const theme = useMantineTheme()
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState('');
    const dataStore = useStore((state: any) => state.store);
    const matches = useMediaQuery('(max-width: 36em)');

    const onSelectionGender = (value: string) => {
        setGender(value)
        if (value == "female") {
            cookies.set("hidePopup", true)
            closeModal()
        }
    };
    
    const closeModal = () => {
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal {...props} onClose={closeModal} loading={loading} size="md" >
            <Box p={20} pt={0} mih={250}>
                <Box w={"100%"} h={"100%"} px={0} >
                    <Stack justify='flex-start' align='center' gap={0}>
                        <Image src={"/logo.png"} w={100} h={50} />
                        <Text mt={10} size='xl' ff={"Cairo"} fw={700} c={"gray.9"}>مرحبا بك في متجر ديلان</Text>
                        <Text mt={10} size='md' ff={"Cairo"} c={"gray.9"} ta={'center'} >متجرنا ليس محلل للرجال ❌</Text>
                    </Stack>
                </Box>
                <Radio.Group 
                    value={gender} onChange={onSelectionGender} label={"هل انت ..؟"} mt={10}
                    styles={{
                        label: {
                            width: "100%",
                            textAlign: "center",
                            fontFamily:"Cairo",
                            fontWeight: 700
                        }
                    }}
                >
                    <Group mt="sm" w={"100%"} justify="center" gap={20}>
                        <Radio 
                            value="male" variant="outline" color={"#963045"}
                            styles={{
                                root: {
                                    width: 100,
                                    height: 40
                                },
                                radio: {
                                    background: "transparent",
                                    borderWidth: 2,
                                    borderRadius: 7,
                                    width: 100,
                                    height: 40,
                                    border: "1px solid #963045"
                                },
                                labelWrapper: {
                                    position: "absolute",
                                    zIndex: 2,
                                    width: 100,
                                    height: 40,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                },
                                label: {
                                    paddingInlineStart: 0,
                                    fontWeight: "700",
                                    fontFamily:"Cairo"
                                },
                                icon: {
                                    display: "none"
                                }
                            }}
                            label={"ذكر"}
                        />
                        <Radio
                            value="female" variant="outline" color={"#963045"}
                            styles={{
                                root: {
                                    width: 100,
                                    height: 40
                                },
                                radio: {
                                    background: "transparent",
                                    borderWidth: 2,
                                    borderRadius: 7,
                                    width: 100,
                                    height: 40,
                                    border: "1px solid #963045"
                                },
                                labelWrapper: {
                                    position: "absolute",
                                    zIndex: 2,
                                    width: 100,
                                    height: 40,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                },
                                label: {
                                    paddingInlineStart: 0,
                                    fontWeight: "700",
                                    fontFamily:"Cairo"
                                },
                                icon: {
                                    display: "none"
                                }
                            }}
                            label={"أنثى"}
                        />
                    </Group>
                </Radio.Group>
            </Box>
        </Modal>
    );
};
import { Text, Container, Group, Image, useMantineTheme, Title, Box, Button, Stack } from '@mantine/core';
import { IconMapPinFilled, IconPhoneFilled, IconMailFilled, IconQuote, IconExternalLink } from '@tabler/icons-react';
import classes from './../../styles/Footer.module.css';
import { useEffect, useRef, useState } from 'react';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import Cookies from 'universal-cookie';
import useStore from '../../store/useStore';
import useAxios from 'axios-hooks';
const cookies = new Cookies(null, { path: '/' });


export function Reviews() {
    const theme = useMantineTheme();
    const autoplay = useRef(Autoplay({ delay: 5000 }));
    let [allReviews, setAllReviews] = useState<any[]>([]);
    const [{ data: dataAllReviews, loading, error }, refetch] = useAxios(
        `${process.env.REACT_APP_API_URL}/review`
    )
    const dataStore = useStore((state: any) => state.store);
  
    useEffect(() => {
        if (dataAllReviews && dataAllReviews.length >= 0) {
            setAllReviews(dataAllReviews)
        }
    }, [dataAllReviews])
    
    if (allReviews?.length === 0) {
        return (<></>)
    }

    return (
        <section style={{marginTop: 80, background: theme.colors.gray[1], padding: "50px 0"}}>
            <Text size={"24px"} ta={'center'} fw={'bold'} mb={30}>اراء العملاء</Text>
            {allReviews?.filter((item: any) => item?.name !== "").length > 0
                ? <Carousel 
                    dir='ltr' loop dragFree align="center" 
                    plugins={[autoplay.current]} onMouseEnter={autoplay.current.stop} onMouseLeave={autoplay.current.reset}
                >
                    {allReviews?.map((item: any, index: number) => (
                        <Carousel.Slide key={index} bg={theme.colors.gray[1]} display={'flex'} style={{justifyContent: "center"}}>
                            <Box w={"40%"} p={20} h={150} bg={"#fff"} display={'flex'} style={{justifyContent: "center", flexDirection: 'column', alignItems: 'center'}}>
                                <Group w={"100%"} justify='flex-end'>
                                    <IconQuote size={24} color='#999' />
                                </Group>
                                <Stack w={"100%"} justify='flex-start'>
                                    <Text size={"16px"} ta={'center'}>{item?.name}</Text>
                                    <Text size={"14px"} fs="italic" ta={'center'} c={'gray'}>~{item?.author}~</Text>
                                </Stack>
                                <Group w={"100%"} justify='flex-start'>
                                    <IconQuote size={24} color='#999' style={{transform: "rotate(180deg)"}} />
                                </Group>
                                <Group w={"100%"} justify='center'>
                                    <Button
                                        variant="subtle" component='a' target='_blank' color='gray' size='xs'
                                        href={`${process.env.REACT_APP_API_URL_IMAGES}/${item?.image}`}
                                        rightSection={<IconExternalLink size={18} />}
                                    >الاثبات</Button>
                                </Group>
                            </Box>
                        </Carousel.Slide>
                    ))}
                </Carousel>
                : null
            }
        </section>
    );
}
import React, { useEffect, useState } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { Products, Reviews } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';
import { BannerHeader } from '../components/Sections/BannerHeader';
import { AlertSelectionGender } from '../components/Modal';
import Cookies from 'universal-cookie';
const cookies = new Cookies(null, { path: '/' });

export function Home () {
    const [openDeleteModal, setOpenDeleteModal] = useState(true);

    useEffect(() => {
        if (cookies.get("hidePopup") == true) {
            setOpenDeleteModal(false)
        }
    }, [cookies.get("hidePopup")])

    return (
        <>
            <HeaderSimple />
            <BannerHeader />
            <Products />
            <Reviews />
            <Footer/>
            <AlertSelectionGender opened={openDeleteModal} onClose={() => setOpenDeleteModal(false)} />
        </>
    );
}
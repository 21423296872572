import { ReactElement, useState } from "react";
import {Modal as ModalUI, ModalProps, Overlay, Loader, Stack, Box, useMantineTheme, ScrollArea} from "@mantine/core";

export type Props = {
    header?: ReactElement;
    footer?: ReactElement;
    size?: string;
    loading?: boolean;
    closeOnClickOutside?: boolean;
} & ModalProps;

function Modal({size, loading, ...props}: Props) {
    const { children, footer } = props;
    const {colors, white} = useMantineTheme();

    return (
        <ModalUI 
            {...props} size={size || "xl"} centered={true} closeOnClickOutside={props.closeOnClickOutside || false}
            withCloseButton={false}
            styles={{
                body: {
                    background: "#ffffffba",
                    position: "relative",
                    padding: 0,
                    borderRadius: 15
                },
                header: {
                    boxShadow: "#bbb 0px 1px 10px -5px"
                },
                content:{
                    background: "transparent",
                    borderRadius: 15,
                    border: "1px solid #963045",
                },
                // overlay:{
                //     background: '#ffffff30',
                // }
            }}
            overlayProps={{
                backgroundOpacity: 0.55,
                blur: 7,
            }}
        >
            <Box p={"md"} bg={"transparent"}>
                {children}
            </Box>

            {/* footer */}
            {footer
                ? <Box 
                    bg={"#fff"} w={"100%"} pos={"sticky"}
                    style={{
                        bottom: 0, zIndex: 1001, left: 0, right: 0,
                        boxShadow: "#bbb 0px 0px 10px -5px"
                    }}
                >
                    {footer}
                </Box>
                : null
            }
        </ModalUI>
    );
}

export default Modal;
